import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate,  useLocation } from 'react-router-dom';
import axios from './axiosConfig';
import Workspace from './WorkSpace';
import NoteEditor from './NoteEditor';
import AuthPage from './AuthPage';
import DocSearch from './DocSearch';
import SavedDocument from './SavedDocument';
import DocumentPage from './DocumentPage'; // Import the new DocumentPage component
import NavigationBar from './NavigationBar';



function isTokenExpired(token) {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  const currentTime = Date.now() / 1000; // in seconds
  return decodedToken.exp < currentTime;
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [accountType, setAccountType] = useState(null); // "free" or "paid"

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token && !isTokenExpired(token)) {
      // Verify token and fetch account type
      axios
        .get('/api/auth/verify', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (response) => {
          if (response.data.status === 'valid') {
            setIsAuthenticated(true);
            // Fetch user account type from backend
            const userInfo = await axios.get('/api/auth/user-info', {
              headers: { Authorization: `Bearer ${token}` },
            });
            setAccountType(userInfo.data.account_type); // Set the account type
          } else {
            setIsAuthenticated(false);
          }
        })
        .catch((error) => {
          console.error('Token verification failed:', error);
          setIsAuthenticated(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setLoading(false);
    }
  }, []);

  const handleLogout = () => {
    console.log('Logged out');
    localStorage.clear();
    sessionStorage.clear();
    setIsAuthenticated(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="mx-20">
      <ConditionalNavigationBar isAuthenticated={isAuthenticated} />
      </div>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />

       

        {/* Route for free and paid users */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              accountType === 'paid' ? (
                <Workspace handleLogout={handleLogout} />
              ) : (
                <Navigate to="/document-search" />
              )
            ) : (
              <Navigate to="/auth" />
            )
          }
        />

        {/* Route to workspace for paid users only */}
        <Route
          path="/workspace"
          element={
            isAuthenticated && accountType === 'paid' ? (
              <Workspace handleLogout={handleLogout} />
            ) : (
              <Navigate to="/document-search" />
            )
          }
        />

        {/* Route to NoteEditor for paid users only */}
        <Route
          path="/notes/:noteId"
          element={
            isAuthenticated && accountType === 'paid' ? (
              <NoteEditor />
            ) : (
              <Navigate to="/auth" />
            )
          }
        />

        <Route 
        path="/saved" 
        element={isAuthenticated ? <SavedDocument /> : <Navigate to="/auth" />}
        />

        {/* Route to document search for all authenticated users */}
        <Route
          path="/document-search"
          element={isAuthenticated ? <DocSearch /> : <Navigate to="/auth" />}
        />
        {/* Open access to DocumentPage without login */}
       <Route path="/document/:id" element={<DocumentPage />} />


        {/* Redirect any unknown routes */}
        <Route path="*" element={<Navigate to={isAuthenticated ? "/" : "/auth"} />} />
      </Routes>
    </Router>
  );
}

function ConditionalNavigationBar({ isAuthenticated }) {
  const location = useLocation();
  const showNavBarRoutes = [ '/workspace', '/document-search', '/saved'];

  // Check for a pattern match for routes like '/document/:id'
  const documentPattern = /^\/document\/[^/]+$/;

  // Check if the current path matches any of the specified routes or the document pattern
  const shouldShowNavBar = showNavBarRoutes.some((route) =>
    location.pathname.startsWith(route)
  ) || documentPattern.test(location.pathname);

  return shouldShowNavBar ? <NavigationBar isAuthenticated={isAuthenticated} /> : null;
}



export default App;