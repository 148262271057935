import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Assuming you're using React Router

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure the base URL is correct
});

// Add an interceptor to attach the token from localStorage
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    console.log('Token from localStorage:', token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Cache-Control'] = 'no-cache';  // Disable caching
    config.headers['Pragma'] = 'no-cache';  // Disable caching for older browsers
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle 401 Unauthorized errors
instance.interceptors.response.use(
  (response) => {
    // If the response is successful, return the response
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, logging out...');
      // Clear token and redirect to login page if token is expired or invalid
      localStorage.removeItem('authToken');
      window.location.href = '/auth';  // Navigate to the login page
    }
    return Promise.reject(error);
  }
);

export default instance;
