import React, { useEffect, useState } from 'react';
import { Bookmark } from 'lucide-react';
import axios from 'axios';
import './DocSearch.css'

const CommunityInsights = ({ dekaName, comments }) => {
    console.log("CommunityInsights comments prop:", comments); // Debugging: Verify the prop

    const [newComment, setNewComment] = useState(""); // Input for new comment
    const [likes, setLikes] = useState({}); // Track likes for each comment

    const handleAddComment = async () => {
        try {
            const encodedDekaName = encodeURIComponent(`${dekaName.replace('/', '_')}`);
            console.log(`Encoded dekaName: ${encodedDekaName}`);  // Debugging statement
    
            const response = await axios.post(`/api/documents/${encodedDekaName}/comments`, { 
                userId : "UserId",
                text: newComment, 
                timestamp: new Date().toISOString() 
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } // Include token here
            });
    
            // Add the new comment to the existing comments state
            comments.push(response.data);  // Assuming response contains the added comment
            setNewComment("");  // Clear input
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };
    


// Handle "Like" button click
const handleLike = (index) => {
    setLikes((prevLikes) => ({
        ...prevLikes,
        [index]: (prevLikes[index] || 0) + 1,
    }));
};

const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);

    const diffInMs = now - date;
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInMinutes < 60) {
        // Within an hour, show as minutes
        return `${diffInMinutes} min${diffInMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInHours < 24) {
        // Within a day, show as hours
        return `${diffInHours} h${diffInHours !== 1 ? '' : ''} ago`;
    } else {
        // More than a day ago, show full date with GMT+7
        const hours = date.getUTCHours() + 7; // Adjust to GMT+7
        const minutes = date.getUTCMinutes();
        const day = date.getUTCDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getUTCFullYear();

        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes.toString().padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}${ampm} ${day} ${month} ${year} (GMT+7)`;
    }
};


return (
    <div className="w-1/4 ml-4 flex flex-col text-sm text-gray-600">
      <h4 className="text-xl font-semibold mb-2 text-gray-800">Community Insights</h4>
      <div className="overflow-y-auto h-80">
        {comments.length > 0 ? (
            comments.map((comment, index) => (
                <div key={index} className="mb-2">
                   {/* UserId and Timestamp on the same row */}
                <div className="flex items-center justify-between">
                    <p className="font-semibold text-gray-700">{comment.userId || "Anonymous"}</p>
                    <p className="text-xs text-gray-500">{formatTimestamp(comment.timestamp)}</p>
                </div>
                <p>{comment.text}</p>
                    <div className="flex items-center mt-1 space-x-2">
                        {/* <button
                            className="text-blue-500 hover:underline"
                            onClick={() => handleLike(index)}
                        >
                            Like ({likes[index] || 0})
                        </button>
                        <button className="text-blue-500 hover:underline">Reply</button> */}
                    </div>
                </div>
            ))
        ) : (
            <p>No comments available.</p>
        )}
      </div>
      <textarea
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Add your insight..."
        className="mt-2 p-2 border rounded-xl h-11 w-full focus:outline-none focus:ring-1 focus:ring-blue-500"
        rows="3"
      ></textarea>
      <button onClick={handleAddComment} className="mt-2 px-3 py-1 text-white bg-blue-500 rounded">
        Comment
      </button>
    </div>
);
};

const SavedDocument = () => {
  const [savedDocuments, setSavedDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  // Format message function
function formatMessage(text) {
    const formattedText = text
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Convert bold syntax to <strong>
      .replace(/\n{2,}/g, '<br /><br />') // Double newlines to new paragraphs
      .replace(/\n/g, '<br />'); // Single newline to line break
  
    return formattedText;
  }

  // Fetch saved documents from the backend API
  useEffect(() => {
    const fetchSavedDocuments = async () => {
      try {
        const response = await axios.get('/api/get-saved-documents?full_info=true', {
            headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        
        setSavedDocuments(response.data.savedDocuments || []);
      } catch (error) {
        console.error('Error fetching saved documents:', error);
      }
    };

    fetchSavedDocuments();
  }, []);

  // Function to remove a saved document
  const removeSavedDocument = async (documentName) => {
    try {
      await axios.post('/api/remove-saved-document', { deka_name: documentName }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
      });
      
      // Update state after successful deletion
      setSavedDocuments((prevDocuments) =>
        prevDocuments.filter((doc) => doc.deka_name !== documentName)
      );
    } catch (error) {
      console.error('Error removing saved document:', error);
    }
  };

  // Category visibility state
  const [categories, setCategories] = useState({
    full_deka: false,
    related_law: true,
    source: false,
    party_name: false,
    department: false,
    current_judge: false,
    case_number: false,
    previous_judge: false,
    initial_case_numbers:false,
  });

  // Mapping for category display names
const categoryDisplayNames = {
    full_deka: 'ย่อยาว',
    related_law: 'กฎหมายที่เกี่ยวข้อง',
    source: 'แหล่งที่มา',
    party_name: 'ชื่อคู่ความ',
    department: 'แผนก',
    current_judge: 'ชื่อองค์คณะ',
    case_number: 'หมายเลขคดีดำ ของศาลฎีกา',
    previous_judge: 'ศาลชั้นต้นและศาลอุทธรณ์',
    initial_case_numbers: 'หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น'
  };

  // Dropdown state
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [comments, setComments] = useState([
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User1', text: 'Interesting point on this case.' },
    { user: 'User2', text: 'I have a question regarding this part.' }
    
    ]);

  // Toggle categories
  const handleCategoryChange = (category) => {
    setCategories((prevCategories) => ({
      ...prevCategories,
      [category]: !prevCategories[category],
    }));
  };
    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
      };

      const handleCheckboxChange = (resultId) => {
        setSelectedDocuments((prevSelected) => {
            // Toggle the selection of the specific result ID
            if (prevSelected.includes(resultId)) {
                // If already selected, remove it from the array
                return prevSelected.filter((id) => id !== resultId);
            } else {
                // If not selected, add it to the array
                return [...prevSelected, resultId];
            }
        });
    };

    // Display selected documents in a new window
  const showSelectedDocumentsInHTML = () => {
    // Filter selected documents based on user selection
    const selectedData = savedDocuments.filter((document) =>
      selectedDocuments.includes(document.deka_name)
    );
  
    const now = new Date();
    const timestamp = now.toLocaleString();
  
    let htmlContent = `
      <html>
        <head>
          <title>Selected Documents</title>
          <style>
            body, html {
              margin: 0;
              padding: 0;
              font-family: Arial, sans-serif;
              color: #333;
              display: flex;
              justify-content: center;
              align-items: center;
              min-height: 100vh;
              width: 100%;
              overflow: auto;
              background-color: #f0f0f0;
            }
            .container {
              width: 210mm;
              margin: auto;
              box-sizing: border-box;
            }
            .document {
              margin: 20px;
              padding: 20px;
              border: 1px solid #ccc;
              background-color: #fff;
              box-sizing: border-box;
            }
            .header {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 10px;
              position: relative;
            }
            .timestamp {
              position: absolute;
              left: 0;
              font-size: 12px;
              color: #555;
            }
            .title {
              font-size: 18px;
              font-weight: bold;
              text-align: center;
            }
            .info-columns {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
            }
            .info-item {
            flex: 1;
            text-align: left;
            }
            .document-content {
              margin-top: 10px;
              line-height: 1.6;
            }
            h2 {
              text-align: center;
              font-size: 16px;
              margin: 10px 0;
            }
            hr {
              margin: 20px 0;
              border: none;
              border-top: 1px solid #ccc;
            }
          </style>
        </head>
        <body>
          <div class="container">
    `;

  
    selectedData.forEach((doc) => {

      htmlContent += `
        <div class="document">
          <div class="header">
            <div class="timestamp">${timestamp}</div>
            <div class="title">คำพิพากษาศาลฎีกาที่ ${doc.deka_name || 'No Title'}</div>
          </div>
          <h2>ฎีกาตัดสินเกี่ยวกับปัญหาข้อกฎหมาย</h2>
          <br>
  
          <div class="info-columns">
            <div class="info-item"><strong>คำพิพากษาศาลฎีกาที่:</strong> ${doc.deka_name || 'ไม่ระบุ'}</div>
            <div class="info-item"><strong>ชื่อคู่ความ:</strong> ${doc.party_name}</div>
        </div>
  
          <div class="document-content">
            <div><strong>กฎหมายที่เกี่ยวข้อง:</strong></div>
            <ul>${
              Array.isArray(doc.related_law)
                ? doc.related_law.map(law => `<li>${law}</li>`).join('')
                : doc.related_law ? `<li>${doc.related_law}</li>` : 'ไม่ระบุ'
            }</ul>
          <div><strong>ย่อสั้น:</strong> ${formatMessage(doc.short_deka || 'ไม่ระบุ')}</div>
          <div><strong>ย่อยาว:</strong> ${formatMessage(doc.full_deka || 'ไม่ระบุ')}</div>
            <div><strong>แหล่งที่มา:</strong> ${doc.source || 'ไม่ระบุ'}</div>
            <div><strong>แผนก:</strong> ${doc.แผนก || 'ไม่ระบุ'}</div>
            <div><strong>ชื่อองค์คณะ:</strong> ${doc.current_judge || 'ไม่ระบุ'}</div>
            <div><strong>หมายเลขคดีดำ ของศาลฎีกา:</strong> ${doc.หมายเลขคดีดำ || 'ไม่ระบุ'}</div>
            <div><strong>ศาลชั้นต้นและศาลอุทธรณ์:</strong> ${doc.previous_judge || 'ไม่ระบุ'}</div>
            <div><strong>หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น:</strong> ${doc.หมายเลขคดีดำและหมายเลขคดีแดงของศาลชั้นต้น || 'ไม่ระบุ'}</div>
          </div>
          <hr />
        </div>
      `;
    });
  
    htmlContent += `
          </div>
        </body>
      </html>
    `;
  
    const newWindow = window.open();
    newWindow.document.write(htmlContent);
    newWindow.document.close();
  };

  const renderSavedDocument = (document) => {
    const isChecked = selectedDocuments.includes(document.deka_name);

    return (
      <li key={document.deka_name} className="mb-4 flex">
        <div className="relative flex-1 p-4 border rounded-xl bg-white shadow-sm">
          <div className="absolute top-4 right-4 flex items-center space-x-2">
            <Bookmark
              onClick={() => removeSavedDocument(document.deka_name)}
              className="cursor-pointer text-blue-500"
              size={24}
              title="Remove from Saved"
            />
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleCheckboxChange(document.deka_name)}
              className="w-5 h-5 text-blue-600 rounded-xl border-gray-300 focus:ring-blue-500"
            />
          </div>

          <div className="flex items-center justify-between mb-2">
            <h3 className="text-xl font-semibold">
              คำพิพากษาศาลฎีกาที่ {document.deka_name}
            </h3>
          </div>
          <br />
            <>
              <p className="text-l font-semibold mb-2">ย่อสั้น</p>
              <div
                dangerouslySetInnerHTML={{ __html: formatMessage(document.short_deka) }}
                className="text-gray-700 mb-4"
              />
            </>
          {categories.full_deka && document.full_deka && (
            <div className="text-gray-700 mb-4">
              <br />
              <p className="text-l font-semibold mb-2">ย่อยาว</p>
              <div dangerouslySetInnerHTML={{ __html: formatMessage(document.full_deka) }} />
            </div>
          )}
          <div className="grid grid-cols-2 gap-4 mb-4">
            {categories.related_law && (
              <div>
                <strong>กฎหมายที่เกี่ยวข้อง:</strong>
                <p className="text-gray-600">{document.related_law}</p>
              </div>
            )}
            {categories.source && (
              <div>
                <strong>แหล่งที่มา:</strong>
                <p className="text-gray-600">{document.source}</p>
              </div>
            )}
            {categories.party_name && (
              <div>
                <strong>ชื่อคู่ความ:</strong>
                <p className="text-gray-600">{document.party_name}</p>
              </div>
            )}
            {categories.department && (
              <div>
                <strong>แผนก:</strong>
                <p className="text-gray-600">{document.department}</p>
              </div>
            )}
            {categories.current_judge && (
              <div>
                <strong>ชื่อองค์คณะ:</strong>
                <p className="text-gray-600">{document.current_judge}</p>
              </div>
            )}
            {categories.case_number && (
              <div>
                <strong>หมายเลขคดีดำ ของศาลฎีกา:</strong>
                <p className="text-gray-600">{document.case_number}</p>
              </div>
            )}
            {categories.previous_judge && (
              <div>
                <strong>ศาลชั้นต้นและศาลอุทธรณ์:</strong>
                <p className="text-gray-600">{document.previous_judge}</p>
              </div>
            )}
            {categories.initial_case_numbers && (
              <div>
                <strong>หมายเลขคดีดำ และ หมายเลขคดีแดง ของศาลชั้นต้น:</strong>
                <p className="text-gray-600">{document.initial_case_numbers}</p>
              </div>
            )}
          </div>
        </div>

      {/* Community Insights */}
      <CommunityInsights comments={document.comments || []} dekaName={document.deka_name} />
            
        </li>
    );
  };


  return (
    <div className="mx-20">
      <div className="px-8 py-4">
        <div className="flex items-center justify-between mb-4">
          {/* Button for selected documents on the left */}
          <button
            onClick={showSelectedDocumentsInHTML}
            className={`px-4 py-2 rounded-xl ${
              selectedDocuments.length === 0
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-slate-900 text-white'
            }`}
            disabled={selectedDocuments.length === 0}
          >
            เตรียมพิมพ์คำพิพากษาที่เลือก
          </button>

          {/* Category Dropdown Button on the right */}
          <div className="relative">
            <button
              className="px-4 py-2 bg-slate-900 text-white rounded-xl"
              onClick={toggleDropdown}
            >
              รูปแบบการแสดงผล
            </button>

            {/* Dropdown with Checkboxes */}
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg p-4 z-10">
                {Object.keys(categories).map((category) => (
                  <label key={category} className="flex items-center space-x-2 mb-2">
                    <input
                      type="checkbox"
                      checked={categories[category]}
                      onChange={() => handleCategoryChange(category)}
                    />
                    <span className="capitalize">
                      {categoryDisplayNames[category] || category.replace('_', ' ')}
                    </span>
                  </label>
                ))}
              </div>
            )}
          </div>
        </div>

        <h2 className="text-2xl font-bold mb-4">Saved Documents</h2>
        {savedDocuments.length === 0 ? (
          <p className="text-gray-600">No saved documents found.</p>
        ) : (
          <ul className="space-y-4">
            {savedDocuments.map((document) => renderSavedDocument(document))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SavedDocument;