import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './axiosConfig'; 


export default function AuthPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [authMode, setAuthMode] = useState('signin');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState(''); // Added state for username
  const [accountType, setAccountType] = useState('free'); // Added state for account type
  const [signupDate] = useState(new Date().toISOString()); // Auto-generated signup date
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success messages
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [resetEmail, setResetEmail] = useState(''); // State for email in forgot password modal
  const [newPassword, setNewPassword] = useState(''); // New password input
  const [confirmPassword, setConfirmPassword] = useState(''); // Confirm password input
  const [passwordsMatch, setPasswordsMatch] = useState(true);


  const navigate = useNavigate();

  async function onSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');

    try {
      if (authMode === 'signin') {
        const response = await axios.post('/api/auth/signin', { email, password });
        if (response.data.access_token) {
          localStorage.setItem('authToken', response.data.access_token);
          localStorage.setItem('userId', email);
          setTimeout(() => {
            window.location.href = '/';
          }, 100);
        } else {
          throw new Error('No token returned from the API.');
        }
      } else if (authMode === 'signup') {
        // Post request for user sign-up
        const response = await axios.post('/api/auth/signup', {
          email,
          password,
          username,
          account_type: 'free',
          signup_date: signupDate,
        });
        setAuthMode('signin');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setUsername('');
        setSuccessMessage('Sign up successful! Please log in with your new account.');
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
  }



 // Handle password reset submission
async function handleResetPasswordSubmit(event) {
  event.preventDefault();
  setIsLoading(true);
  setErrorMessage('');
  setSuccessMessage('');

  // Check if passwords match
  if (newPassword !== confirmPassword) {
    setPasswordsMatch(false);
    setIsLoading(false);
    return;
  }

  try {
    await axios.post('/api/auth/reset-password', { email: resetEmail, new_password: newPassword }); // Change newPassword to new_password
    setSuccessMessage('Password reset successful! You can now log in with your new password.');
    setShowForgotPasswordModal(false);
  } catch (error) {
    setErrorMessage(error.response?.data?.message || error.message);
  } finally {
    setIsLoading(false);
  }
}

  

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
    {/* Left Side - Authentication */}
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f4f8', flexDirection: 'column' }}>
      
      {/* Welcome Message Based on Auth Mode */}
      <h2 style={{ fontSize: '28px', fontWeight: '600', marginBottom: '20px', color: '#333' }}>
        {authMode === 'signin' ? 'Hi, Welcome Back' : 'Create Your KHORN Account'}
      </h2>
      
      <div
        style={{
          width: '400px',
          height: 'auto',
          padding: '30px',
          borderRadius: '10px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
          {/* Title and Description Above Tabs */}
          <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <h1 style={{ fontSize: '24px', fontWeight: '600', marginBottom: '10px' }}>
              {authMode === 'signin' ? 'Sign In' : 'Sign Up'}
            </h1>
            <p style={{ fontSize: '14px', marginBottom: '10px', color: '#555' }}>
              {authMode === 'signin'
                ? 'Sign in to your account to access the workspace.'
                : 'Create a new account to get started with KHORN.'}
            </p>
          </div>

          {/* Tabs for Sign-In/Sign-Up */}
          <div style={{ display: 'flex', borderBottom: '2px solid #ccc', marginBottom: '20px' }}>
            <div
              onClick={() => setAuthMode('signin')}
              style={{
                padding: '10px',
                cursor: 'pointer',
                fontWeight: authMode === 'signin' ? 'bold': 'normal',
                borderBottom: authMode === 'signin' ? '2px solid #000000' : 'none',
                color: authMode === 'signin' ? '#000000' : '#000',
                flex: 1,
                textAlign: 'center',
              }}
            >
              Sign In
            </div>
            <div
              onClick={() => setAuthMode('signup')}
              style={{
                padding: '10px',
                cursor: 'pointer',
                fontWeight: authMode === 'signup' ? 'bold' : 'normal',
                borderBottom: authMode === 'signup' ? '2px solid #000000' : 'none',
                color: authMode === 'signup' ? '#000000' : '#000',
                flex: 1,
                textAlign: 'center',
              }}
            >
              Sign Up
            </div>
          </div>

          {/* Error message */}
          {errorMessage && (
            <div style={{ color: 'red', marginBottom: '10px', textAlign: 'center' }}>
              {errorMessage}
            </div>
          )}
          {/* Success message */}
          {successMessage && (
            <div style={{ color: 'green', marginBottom: '10px', textAlign: 'center' }}>
              {successMessage}
            </div>
          )}

          
          <div style={{ flexGrow: 1, textAlign: 'left' }}>
            <form onSubmit={onSubmit}>
              <div style={{ marginBottom: '15px' }}>
                <label htmlFor="email" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  placeholder="m@example.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
              </div>
              {/* Password input only if authMode is 'signin' */}
              {authMode === 'signin' && (
                <>
                  {/* Password Input */}
                    <div style={{ marginBottom: '15px' }}>
                      <label htmlFor="password" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
                        Password
                      </label>
                      <input
                        id="password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
                      />
                    </div>

                  {/* Forget Password for Sign In */}
                  <div style={{ marginBottom: '15px' }}>
                  <div style={{ marginBottom: '15px' }}>
                  <button type="button" onClick={() => setShowForgotPasswordModal(true)} style={{ color: '#000', textDecoration: 'none', fontSize: '14px', border: 'none', background: 'none' }}>Forgot password?</button>
                </div>
                  </div>
                </>
              )}


      {/* Signup */}
      {authMode === 'signup' && (
  <>
    {/* Password Input */}
    <div style={{ marginBottom: '15px' }}>
      <label htmlFor="password" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
        Password
      </label>
      <input
        id="password"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
      />
    </div>
  </>
)}


      {authMode === 'signup' && (
      <div style={{ marginBottom: '15px' }}>
        <label htmlFor="confirm-password" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
          Confirm Password
        </label>
        <input
          id="confirm-password"
          type="password"
          required
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
            setPasswordsMatch(e.target.value === password);
          }}
          style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
        />
        {!passwordsMatch && <p style={{ color: 'red', marginTop: '5px' }}>Passwords do not match.</p>}
      </div>
        )}

        {/* Username Input for Sign-Up */}
        {authMode === 'signup' && (
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="username" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
              Username
            </label>
            <input
              id="username"
              type="text"
              placeholder="Your username"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          </div>
        )}
        {/* Account Type Selection for Sign-Up */}
        {/* {authMode === 'signup' && (
          <div style={{ marginBottom: '15px' }}>
            <label htmlFor="account-type" style={{ display: 'block', marginBottom: '5px', fontWeight: '500' }}>
              Account Type
            </label>
            <select
              id="account-type"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value)}
              style={{ width: '100%', padding: '7px', borderRadius: '4px', border: '1px solid #ccc' }}
            >
              <option value="free">Free</option>
              <option value="paid">Paid</option>
            </select>
          </div>
        )} */}
              {/* Submit Button */}
        <button
          type="submit"
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#000000',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '20px',
          }}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : authMode === 'signin' ? 'Sign In' : 'Sign Up'}
        </button>
            </form>
          </div>
        </div>
        {/* Forgot Password Modal */}
        {showForgotPasswordModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg w-80">
              <h2 className="text-xl font-semibold mb-4">Reset Password</h2>
              <form onSubmit={handleResetPasswordSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Email</label>
                  <input
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    required
                    placeholder="Enter your email"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">New Password</label>
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    placeholder="Enter new password"
                    className="w-full px-3 py-2 border rounded-md focus:outline-none"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 mb-2">Confirm Password</label>
                  <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                      setPasswordsMatch(e.target.value === newPassword);
                    }}
                    required
                    placeholder="Confirm new password"
                    className={`w-full px-3 py-2 border rounded-md focus:outline-none ${
                      passwordsMatch ? 'focus:ring-blue-500' : 'focus:ring-red-500'
                    }`}
                  />
                  {!passwordsMatch && <p className="text-red-600 text-sm">Passwords do not match</p>}
                </div>
                <div className="flex justify-between items-center">
                  <button
                    type="submit"
                    className="py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                    disabled={isLoading || !passwordsMatch}
                  >
                    {isLoading ? 'Processing...' : 'Reset Password'}
                  </button>
                  <button
                    type="button"
                    className="py-2 px-4 text-gray-500 hover:text-gray-700"
                    onClick={() => setShowForgotPasswordModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
              {errorMessage && <p className="text-red-600 mt-4">{errorMessage}</p>}
              {successMessage && <p className="text-green-600 mt-4">{successMessage}</p>}
            </div>
          </div>
        )}
      </div>

      {/* Right Side - Company Details */}
      <div style={{ flex: 1, backgroundColor: '#f5f5f5', padding: '40px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <h1 style={{ fontSize: '32px', fontWeight: '700', marginBottom: '20px' }}>Welcome to Khorn</h1>
        <p style={{ fontSize: '16px', textAlign: 'center', maxWidth: '400px', lineHeight: '1.5' }}>
          Khorn is an AI-powered tool that helps lawyers focus on their core tasks by providing efficient access to legal documents and enhanced research capabilities.
        </p>
        <p style={{ fontSize: '14px', marginTop: '20px', color: '#888', maxWidth: '400px', textAlign: 'center' }}>
          "Save countless hours of work and deliver stunning insights faster than ever before."
        </p>
      </div>
    </div>
  );
}
